import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
// import { PrimaryButton  } from "components/misc/Buttons.js";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as JoinIcon } from "images/join.svg";
import { ReactComponent as LoginIcon } from "images/ApproveIcon.svg";
import { ReactComponent as ApproveIcon } from "images/LoginIcon.svg";
import { ReactComponent as MfaIcon } from "images/MfaIcon.svg";
import { ReactComponent as SecurityIcon } from "images/SecurityIcon.svg";
import { ReactComponent as SsoIcon } from "images/SsoIcon.svg";
// import TeamIllustrationSrc from "images/organizations.svg";

const Container = tw.div`relative -mt-24`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
// const ImageColumn = tw(Column)`md:w-3/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

// const Image = styled.div((props) => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded bg-contain bg-no-repeat bg-center h-full -ml-24 mt-12`,
// ]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-semibold text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium text-secondary-100`;

const Features = tw.div`-mt-8 max-w-screen-lg container-center md:mx-0 flex space-x-12`;
// const Features2 = tw.div`mt-8 max-w-screen-lg  md:mx-0`;
// const Feature1 = tw.div`mt-8 flex items-start space-x-6`;
const Feature = tw.div`mt-12 flex items-start flex container-center`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-100 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-100`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-100`;
const FeatureDescription = tw.div`mt-1 text-sm`;

// const PrimaryButton = tw(
//   PrimaryButtonBase
// )`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default ({
  subheading = "Our Customers",
  heading = (
    <>
      Why Organisations<span tw="text-primary-100"> Choose vID</span>
    </>
  ),
  description = "Well, at the risk of sounding like a broken record, trust is everything. Without trust in their customers and citizens, organisations and governments won’t be able to pursue the digital transformations that they need to level up the services they provide.Similarly, in the absence of trust, consumers won’t feel comfortable using online tools, which may mean they miss out on access to essential services – a major barrier to inclusion.Finally, an absence of trust in the IoT sphere can create huge friction in device interaction and could end up severely hampering the development of the technology in the coming years.",
  primaryButtonText = "See Our Portfolio",
  primaryButtonUrl = "https://timerse.com",
  features1 = null,
  features2 = null,

  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: JoinIcon,
      title: "Joining Organization",
      description:
        "An employee or contractor joins an organization and on day one can dive right into work and onboarding because they have access to everything they need—and nothing more.",
    },
    {
      Icon: MfaIcon,
      title: "Multi Factor Authentication",
      description:
        "MFA is a core component of a strong identity and access management (IAM) policy. Rather than just asking for a username and password, MFA requires one or more additional verification factors, which decreases the likelihood of a successful cyber attack.",
    },
    {
      Icon: SecurityIcon,
      title: "Security",
      description:
        "There is true organizational transparency on who has access to what, with strong security controls.In reality, there are so many mistakes that can leave your account vulnerable to cyber threats.",
    },
  ];
  const defaultFeatures2 = [
    {
      Icon: LoginIcon,
      title: "Approve Manually",
      description:
        "Lines of business, application owners and supervisors/managers only have to manually approve access when it involves high-risk entitlements and roles.",
    },
    {
      Icon: ApproveIcon,
      title: "Interoperable Login",
      description:
        "Once a user has verified their identity with vID, that person will never have to re-verify their identity again across any organization where vID is integrated.",
    },

    {
      Icon: SsoIcon,
      title: "Single Sign ON",
      description:
        "The focus of the single sign-on (SSO) today is central government services. In the future the sign-on will be available to the wider public sector and the private sector too. There are no plans to link a digital signature to the SSO at this time, though it is certainly possible.",
    },
  ];

  if (!features1) features1 = defaultFeatures;
  if (!features2) features2 = defaultFeatures2;

  return (
    <Container>
      <TwoColumn>
        {/* <ImageColumn>
          <Image imageSrc={TeamIllustrationSrc} />
        </ImageColumn> */}
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>

            {/* <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
      <Features>
        {features1.map((feature, index) => (
          <Feature key={index}>
            <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer>
            <FeatureText>
              <FeatureHeading>{feature.title}</FeatureHeading>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </FeatureText>
          </Feature>
        ))}
      </Features>
      <br></br>
      <Features>
        {features2.map((feature, index) => (
          <Feature key={index}>
            <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer>
            <FeatureText>
              <FeatureHeading>{feature.title}</FeatureHeading>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </FeatureText>
          </Feature>
        ))}
      </Features>

      {/* <Features2>
              {features2.map((feature, index) => (
                <Feature1 key={index}>
                  <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>{feature.description}</FeatureDescription>
                  </FeatureText>
                </Feature1> */}

      {/* </Features2> */}
    </Container>
  );
};
