import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, SectionHeading2 } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/bg-svgs/m.svg";

import SupportIconImage from "../../images/efficient.svg";
import ShieldIconImage from "../../images/convenient.svg";
import CustomizeIconImage from "../../images/reusable.svg";
import FastIconImage from "../../images/secure.svg";
import ReliableIconImage from "../../images/private.svg";
import SimpleIconImage from "../../images/convenient.svg";

const Container = tw.div`relative bg-primary-100 -mx-8 px-8 text-gray-100 mt-12 flex`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center mx-auto py-10 md:py-12`}
`;
const Heading = tw(SectionHeading2)`w-full text-5xl font-semibold`;

const Column = styled.div`
  ${tw`px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-0 border-gray-lightest rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-0 border-gray-lightest text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-gray-lightest`}
  }

  .description {
    ${tw`mt-3 font-semibold text-gray-lightest text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-32 opacity-50 transform translate-x-16 translate-y-12 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Convenient",
      description: "You can use it on the go, at any time of day."
    },
    { imageSrc: SupportIconImage, title: "Efficient",description:
    "It can save you time you want to access online services.." },
    { imageSrc: CustomizeIconImage, title: "Reusable",description:
      "Set it up once, then reuse it again and again whenever you are asked to prove	who you are." },
    { imageSrc: ReliableIconImage, title: "Private",description:
      "You can be confident your information is private & protected." },
    { imageSrc: FastIconImage, title: "Secure",description:
      "It's secure so you don’t need to remember passwords." }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading><span tw="text-gray-lightest">Key Features</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title" >{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
