import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/bg-svgs/c.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/bg-svgs/b.svg";
import Svg1 from "../../images/eidstandard.svg";
import Svg2 from "../../images/eidconfidential.svg";
import Svg3 from "../../images/eidprivate.svg";

const Container = tw.div`relative -mt-24`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center -mt-12 lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
// const Svg1 = tw.div`rounded-b-none`;
// const Image2 = tw.div`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`;
// const Image3 = tw.div`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`;
const HeadingDescription = tw.p`mt-4 font-semibold text-gray-500 text-center max-w-sm`;
// const HeadingDescription2 = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;
// const HeadingDescription3 = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-32 rounded-b-none bg-no-repeat`
]);

const Details = tw.div`p-6 rounded border-2 mt-8 rounded-t-none flex-1 flex flex-col items-center text-center lg:block`;
const MetaContainer = tw.div`flex items-center mt-6`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-8 leading-snug text-2xl`;
const Description = tw.p`mt-6 text-sm text-blue-100 text-lg font-bold`;
const Description2 = tw.p`mt-4 text-sm text-secondary-100`;
const Description3 = tw.p`mt-4 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({ as: "a" })`
  ${tw`inline-block w-64 mt-8 text-sm font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-24 translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 w-48 h-48 transform -translate-x-24 translate-y-full opacity-25`;

export default ({

  subheading = "Blog",
  heading = <> <span tw="text-primary-100 font-semibold">Subscription Plans.</span></>,
  description = "We have three major plans for new business owners or a stable organization.",
  description2 = "asdsf asdas",
  description3 = "asdsf asdafr dfd",
}) => {
  const blogPosts = [
    {
      imageSrc: Svg1,
      // "https://images.unsplash.com/photo-1479660095429-2cf4e1360472?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      author: "User",
      category: "Secure",
      title: "vID Standard",
      description: "30 DAYS FREE SUBSCRIPTION",
      description2: "Secure at rest and storage",
      description3: "1000 verifiable identities",
      text: "START FOR FREE",

      url: "https://reddit.com"
    },
    {
      imageSrc: Svg2,
      // "https://images.unsplash.com/photo-1479660095429-2cf4e1360472?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      author: "User",
      category: "Secure",
      title: "vID Confidential",
      description: "30 DAYS FREE SUBSCRIPTION",
      description2: "Secure at rest, storage and execution",
      text: "START FOR FREE",


      description3: "1000 verifiable identities",
      url: "https://timerse.com"
    },
    {
      imageSrc: Svg3,
      // "https://images.unsplash.com/photo-1579869847514-7c1a19d2d2ad?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80",
      author: "User",
      category: "Secure",
      title: "vID Private",
      description: "CUSTOMISABLE",
      description2: "More Information can be provided as part of the quotation",

      text: "REQUEST A QOUTE",

      // description3: "1000 verifiable identities",
      url: "https://timerse.com"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Details>
                  <Image imageSrc={post.imageSrc} />
                  <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{post.author}</div>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{post.category}</div>
                    </Meta>
                  </MetaContainer>
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  <Description2>{post.description2}</Description2>
                  <Description3>{post.description3}</Description3>
                  <Link href={post.url}>{post.text}</Link>
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
