import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading2, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
// import { ReactComponent as SvgDecoratorBlob1 } from "images/bg-svgs/m.svg";
// import { ReactComponent as SvgDecoratorBlob2 } from "images/bg-svgs/m.svg";

import defaultCardImage from "images/register.svg";

import SupportIconImage from "images/account.svg";
import ShieldIconImage from "images/register.svg";
import CustomizeIconImage from "images/identity.svg";
import FastIconImage from "images/singleeid.svg";
import ReliableIconImage from "images/verify.svg";
// import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative bg-primary-100 -mx-8 px-8 text-gray-100 mt-24`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap bg-primary-100 md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-lightest`;
const Heading = tw(SectionHeading2)`w-full text-5xl font-semibold`;
const Description = tw(SectionDescription)`w-full text-center text-gray-lightest`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col  items-center sm:items-center text-center sm:text-center h-full mx-16 px-4 py-8`}
  .imageContainer {
    ${tw`bg-gray-lightest text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-12 h-12`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`tracking-wider text-gray-lightest font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;
// const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
//   ${tw`-z-20 relative h-32 w-32 opacity-75`}
// `;
// const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
//   ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
// `;
export default ({

  cards = null,
  heading = "How it works",
  subheading = "",
  description = "We verify your identity and give you an VERYFID to use everywhere and anytime."

}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Register",
      description: "Email and phone number required."
    },
    {
      imageSrc: SupportIconImage, title: "Account Verification",
      description: "Receive OTP and enter OTP."
    },
    {
      imageSrc: CustomizeIconImage, title: "Identity Verification",
      description: "Submit governament document i-e nic, passport etc"
    },
    {
      imageSrc: ReliableIconImage, title: "VERYFID Provision",
      description: "Unique Digital Identity is provided"
    },
    {
      imageSrc: FastIconImage, title: "Use Single VERYFID everywhere",
      description: "You are a digital person globally."
    },
    // { imageSrc: SimpleIconImage, title: "Easy" }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>

        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>How it works</Heading>

        {description && <Description>{description}</Description>}
        <VerticalSpacer />

        {cards.map((card, i) => (
          <Column key={i}>
            <Card>

              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>

    </Container>
  );
};
