import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
// import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
// import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/bg-svgs/a.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/bg-svgs/a.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full text-primary-100 font-semibold`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;


export default ({
  subheading = "FAQS",
  heading = "You have Questions ?",
  description = "And we have got answers to all of them. Digital Identity makes accessing government services online simpler, safer and more secure.",
  faqs = [
    {
      question: "Who can use vID™ ?",
      answer:
        "vID™ is a global subscription-based service available for everyone to use. It can be used by government agencies & individuals working on digital identity programs and private organizations looking forward to establishing their own digital identity ecosystems."
    },
    {
      question: "How do I verify my identity?",
      answer:
        "If you created an account through the vID.vialogic.co website, you may have been redirected to the vid.verifydocument. To verify your identity, you will need to access a link or webpage provided by the organization, such as a nadra,fbr,secp etc, for which you need to verify your identity."
    },
    // {
    //   question: "Why does vID ask for my Nadra Number(NIC or CNIC)?",
    //   answer: "vID verifies the identities of millions of people across the world. Our primary goal is to make sure that you are who you say you are so that you can receive access to exclusive benefits or permissions from our partners.Sometimes, our verification process requires collecting sensitive pieces of information, like your National Identity Card (NIC) or a photo of a document. The information we ask for helps us prevent duplication, impersonation, and deception. It makes sure that your account can never be duplicated—whether accidentally by you or in a malicious attempt by someone pretending to be you. Information you provide is secured and encrypted, and is used only to facilitate verification and prevent fraud. "
    // },
    {
      question: "Why were my identity documents not accepted?",
      answer: " If your identity documents were not accepted, this may be due to a number of reasons: Your submission may have contained an error or typo. You may have submitted expired documents. You may not have correctly taken and submitted a 'selfie' when prompted. You may have submitted an unreadable document. You may have submitted a document that has already been uploaded by another ID.me member."
    },
    {
      question: "How Confidentiality and Privacy is enforced?",
      answer:
        "vID™ encrypts all citizen data and uses the Confidential Compute model to ensure that the data can be accessed only through explicit user consent within the consortium members’ policies. All identity transactions are completely private and cannot be accessed by third parties or network operators without an owner’s explicit permission."
    },
    {
      question: "What is multi-factor authentication (MFA)?",
      answer: "When you have MFA set up for your vID Account, you will take additional steps to confirm your identity when you sign in with your email address and password. In the initial set up of MFA, you will be presented several MFA options to choose from; for example, you may set up verification codes as your MFA method, which will send a one-time use code to a trusted device, sometimes via a trusted phone number. You could also sign in to vID on your computer, then receive a verification code via a call or text on your phone. Enter the code on the device you are using to access vID, and the sign-in is complete.Depending on your account set-up and the partner website you are working with, you may be asked to provide MFA credentials every time you sign in, or you may be permitted to 'trust' sign-ins that come from certain devices."
    },
    {
      question: "How Confidential Computing works?",
      answer:
        "vID™ relies on the Confidential Compute (Trusted Execution Environment) technology to execute secure transactions in untrusted environments. The technology deploys protected enclaves for identity workflows and provides strong data protection to all identity transactions."
    },
    {
      question: "What is the subscription cost?",
      answer:
        "vID™ can be deployed as a Lite instance (suitable for pre-production integration) or as an Enterprise instance with full support of Confidential Computing. Please refer to our Pricing page for more details."
    },
    {
      question: "What is the implementation process?",
      answer: "vID™ is available as a SaaS service and can be deployed in a public cloud through our SaaS Portal. The deployment is fully automated, and the system is ready to use once it is deployed. "
    }
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
