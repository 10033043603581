import React from "react";
// import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ThreeColWithSideImage from "components/features/ThreeColWithSideImage";
import ThreeColSimpleWithImageAndDashedBorder from "components/blogs/ThreeColSimpleWithImageAndDashedBorder";
import TwoColWithTwoFeaturesAndButtons from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import WithDigital from "components/features/WithDigital";
import DigitalInBusiness from "components/features/DigitalInBusiness";
import BusinessHero from "components/hero/BusinessHero";



// const HighlightedText = tw.span`text-primary-500`

export default () => {
  return (
    <AnimationRevealPage>
      <BusinessHero />
      <ThreeColWithSideImage />

      <ThreeColSimpleWithImageAndDashedBorder />


      <TwoColWithTwoFeaturesAndButtons />
      <WithDigital />
      <DigitalInBusiness />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
}
