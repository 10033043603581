import React from "react";
// import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { SecondaryButton as SecondaryButtonBase } from "components/misc/Buttons.js";
import { TertiaryButton as TertiaryButtonBase } from "components/misc/Buttons.js";
import {
  Container,
  ContentWithVerticalPadding,
} from "components/misc/Layouts.js";
// import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
// import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg";
import rightimage from "../../images/mobileview1.png";
import mobileviewicon from "../../images/mobileviewicon.svg";
import android from "../../images/android.svg";
import ios from "../../images/ios.svg";

const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(
  Column
)`-mt-24 mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(
  SectionHeading
)`text-left text-primary-100 font-semibold leading-snug lg:text-2xl mt-4`;
const Description = tw(
  SectionDescription
)`mt-8 lg:text-base font-semibold text-gray-700 max-w-lg`;
const SecondaryButton = tw(
  SecondaryButtonBase
)`-mt-48 font-semibold w-3/12 text-center py-2`;
const TertiaryButton = tw(TertiaryButtonBase)`-mt-48 font-semibold text-center`;
const Button = tw(SecondaryButtonBase)``;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block font-semibold w-7/12 tracking-wide text-center py-4`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const ImageColumn = tw(
  Column
)`ml-auto lg:mr-0 relative w-96 mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96  rounded-t sm:rounded relative z-20`;

export default ({
  heading = "vID provides a platform for citizens and organizations to carry out transactions through the digital medium in a safe and verifiable way",
  description = "Digital public services require reliable identity proofing services that are trusted by the general public.",
  imageSrc = rightimage,
  SecondaryButtonText = "Digital Citizens",

  primaryButtonUrl = "https://play.google.com/store/apps/details?id=eidnow.digitalcitizen&hl=en&gl=US",
  primaryButtonText = "Get Mobile App",
  buttonRounded = false,
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;

  return (
    <>
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <ImageColumn style={{ marginLeft: "190px" }}>
              <ImageContainer>
                <Image
                  src={imageSrc}
                  style={{ width: "290px", height: "600px" }}
                />
              </ImageContainer>
            </ImageColumn>
            <TextColumn style={{ marginRight: "150px" }}>
              <Image
                src={mobileviewicon}
                style={{ width: "50px", height: "50px" }}
              />
              <br></br>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <br></br>
              <TertiaryButton
                as="a"
                href={primaryButtonUrl}
                css={buttonRoundedCss}
              >
                {primaryButtonText}
              </TertiaryButton>
              <div
                style={{
                  display: "flex",
                }}
              >
                <a href={primaryButtonUrl} style={{ marginTop: "40px" }}>
                  <img src={android} width="60px" height="60px" />
                </a>
                <a href="#" style={{ marginTop: "40px", marginLeft: "10px" }}>
                  <img src={ios} width="60px" height="60px" />
                </a>
              </div>
            </TextColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
