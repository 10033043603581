import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { journey, SectionHeading, SectionHeading3, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { DigitalCitizen, LegalEntity, PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/bg-svgs/a.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/bg-svgs/a.svg";
import PortfolioTwoCardsWithImage from "components/cards/PortfolioTwoCardsWithImage";
import DC from "images/digitalcitizenimage.png";
import LE from "images/legalentityimage.png";
import { BsFillArrowRightCircleFill } from 'react-icons/bs';

const HeaderContainer = tw.div`w-full flex flex-col items-center -mt-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-2xl`;
const Heading = tw(SectionHeading)`w-full`;
const Heading1 = tw(journey)`w-full text-2xl font-semibold`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-80 mt-16 md:mr-32 md:last:mr-16 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${props =>
        props.featured &&
        css`
      ${tw`border-4 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-2 -mx-8 bg-gray-lightest rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-4 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

const PlanAction = tw.div`px-4 pb-2`;
const BuyNowButton = styled(DigitalCitizen)`
  ${tw`py-6`}
`;
const PlanAction2 = tw.div`px-4 pb-8`;
const BuyNowButton2 = styled(LegalEntity)`
  ${tw`py-6`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({
    subheading = "Pricing",
    heading = "Flexible Plans.",
    description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    plans = null,
    primaryButtonText = {
        LE: "Digital Identity for Business Owners",
        DC: "Digital Identity for Digital Citizens"
    },
    planDurations = [
        {
            text: "Digital Citizen",
            switcherText: "Monthly",
        },
        {
            text: "Year",
            switcherText: "Yearly",
        }
    ]
}) => {
    const defaultPlans = [
        {
            name: "",
            durationPrices: [],
            mainFeature: "",
            features: [""]
            // Image: 
        },
        {
            name: "Pro Plan",
            durationPrices: ["$49", "$499"],
            mainFeature: "Suited for Production Websites",
            features: ["60 Templates", "8 Landing Pages", "22 Internal Pages", "Priority Assistance", "Lifetime Updates"],
            featured: true
        }
    ];

    if (!plans) plans = defaultPlans;

    const [activeDurationIndex, setActiveDurationIndex] = useState(0);

    return (
        <Container>
            <ContentWithPaddingXl>
                <HeaderContainer>
                    {subheading && <Subheading>Individuals</Subheading>}
                    <Heading1>Give Digital Identities to Individual Digital
                        Citizens to use everywhere, anywhere</Heading1>
                </HeaderContainer>
                {/* <PortfolioTwoCardsWithImage/> */}
                <PlansContainer>
                    <Plan>
                        <PlanHeader>
                            <img src={LE}></img>
                        </PlanHeader>
                        <PlanAction>
                            <BuyNowButton>{primaryButtonText.DC}</BuyNowButton>
                            <button>
                                <BsFillArrowRightCircleFill size={30} color="#063F95" />
                            </button>
                        </PlanAction>
                    </Plan>
                </PlansContainer>
            </ContentWithPaddingXl>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </Container>
    );
};
