import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import GitHubButton from "react-github-btn";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";


/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const HeroRow = tw(Row)`max-w-md flex-col justify-between items-center lg:py-24`;

const Heading = tw(HeadingBase)`text-left text-3xl text-primary-100 font-semibold`;
const Description = tw(DescriptionBase)` text-center lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  window.gtag("js", new Date());
  window.gtag("config", "UA-45799926-9");

  const downloadUrl = "/treact-ui.zip"
  React.useEffect(() => {
    var iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = downloadUrl
    document.body.appendChild(iframe);
  }, [])

  return (
    <AnimationRevealPage disabled>
      <Container tw="px-12 bg-gray-200 rounded">
        <Content2Xl>

          <HeroRow>
            <Heading>WithOut Digital Identity!</Heading>
            <Description tw="text-left">Register online to create a new FBR no,
              register for Secp, register for GOVT and
              register business name <br /><span tw="font-bold text-primary-100">15 minutes</span></Description>
            <div tw="text-left">
            <Description tw="text-left">Post certified documents to Pakistan Business
              Register for review and processing<br />
              <span tw="font-bold text-primary-100">105 minutes</span></Description>
            </div>
            <div tw="text-left">
            <Description tw="text-left">Login to online business profile and manage
              who is authorized to access account
              <br /><span tw="font-bold text-primary-100">15 minutes</span></Description>
            </div>
            <div tw="text-left">
            <Description tw="text-left">
              <span tw="font-bold text-primary-100">Total: 135 minutes (2hrs 15mins) </span>
              </Description>
            </div>
          </HeroRow>
          <HeroRow tw="ml-24">
            <Heading>With Digital Identity!</Heading>
            <Description tw="mt-20 mb-2 text-left">Register online to create a new FBR no,
              register for Secp, register for GOVT and
              register business name <br /><span tw="font-bold text-primary-100">15 minutes</span></Description>
            <div tw="text-left">
            <Description tw="mt-16 mb-4 text-left">
              Login to online business profile and manage
              who is authorized to access account
              <br /><span tw="font-bold text-primary-100">15 minutes</span>
              </Description>
            </div>
            <div>
            <Description tw="text-left">
              <br /><span tw="font-bold text-primary-100">Total : 30 minutes</span>
              </Description>
            </div><br/>
            <div tw="text-left bg-gray-lightest p-4">
            <Description tw="text-left">
              <p class="text-border"><strong>Potential time saved is 1 hour 45 minutes.
              </strong>&nbsp;<strong>Save up to an additional 4 weeks</strong>&nbsp;
                by not having to wait for identity documents to be manually reviewed and processed.
              </p>
              </Description>
            </div>
          </HeroRow>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};
