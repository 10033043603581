import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as JoinIcon } from "images/join.svg";
import { ReactComponent as LoginIcon } from "images/ApproveIcon.svg";
import { ReactComponent as ApproveIcon } from "images/LoginIcon.svg";
import { ReactComponent as MfaIcon } from "images/MfaIcon.svg";
import { ReactComponent as SecurityIcon } from "images/SecurityIcon.svg";
import { ReactComponent as SsoIcon } from "images/SsoIcon.svg";
import TeamIllustrationSrc from "images/nothappy.png";
import TeamIllustrationSrc1 from "images/happy.png";

const Container = tw.div`relative mt-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12 -mt-40`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-3/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full -ml-24 mt-12`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left text-xl font-semibold`;
const Heading = tw(
    SectionHeading
)`mt-4 font-semibold text-2xl sm:text-4xl lg:text-4xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm lg:text-lg font-semibold text-secondary-100`;

const Features = tw.div`-mt-8 max-w-screen-lg container-center md:mx-0 flex space-x-12`;
const Features2 = tw.div`mt-8 max-w-screen-lg  md:mx-0`;
const Feature = tw.div`mt-12 flex items-start flex container-center`;
const Feature1 = tw.div`mt-8 flex items-start space-x-6`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-100 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-100`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-100`;
const FeatureDescription = tw.div`mt-1 text-sm`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default ({
    subheading = "A random citizen",
    heading = (
        <>
            A Successful<span tw="text-primary-100"> Digital Story</span>
        </>
    ),
    description = "Sophia is an IT engineer who has been reluctant to use online government services in the past, preferring to make an hour-long drive to visit a Services Pakistan service center or a Pakistan Post shop front instead. After battling extreme drought, Sophia decides it is time to use government services online and create his Digital Identity so he can quickly set up new online accounts. He can no longer afford to waste hours on the road when he needs to be on the farm. When a bushfire tears through the family property and destroys his family’s birth certificates and passports, Sophia realizes the value of his Digital Identity. With his Digital Identity, he doesn’t need to wait for replacement documents and he can still access all of the government services he needs.",
    primaryButtonText = "See Our Portfolio",
    primaryButtonUrl = "https://timerse.com",
    features1 = null,
    features2 = null,

    textOnLeft = true
}) => {

    // if (!features1) features1 = defaultFeatures;
    // if (!features2) features2 = defaultFeatures2;

    return (
        <div>
            <Container>
                <TwoColumn>
                    <ImageColumn>
                        <Image imageSrc={TeamIllustrationSrc} />
                    </ImageColumn>
                    <TextColumn textOnLeft={textOnLeft}>
                        <TextContent>
                            <Subheading>{subheading}</Subheading>
                            <Heading>{heading}</Heading>
                            <Description>{description}</Description>
                        </TextContent>
                    </TextColumn>
                </TwoColumn>
                <TwoColumn>
                    <ImageColumn>
                        <Image imageSrc={TeamIllustrationSrc1} />
                    </ImageColumn>
                    <TextColumn textOnLeft={textOnLeft}>
                        <TextContent>
                            {/* <Subheading>{subheading}</Subheading> */}
                            <Heading>After Using <span tw="text-primary-100">vID</span></Heading>
                            <Description>In the future, by using Digital Identity, a natural citizen affected
                                by a natural disaster can save $128 in avoided costs and 4 hours applying to
                                replace identity documents and driving into town to apply for assistance.
                                They save up to an additional 4 weeks by not having to wait for new identity
                                documents to be created and sent before they can apply for assistance. </Description>
                        </TextContent>
                    </TextColumn>
                </TwoColumn>
            </Container>
        </div>
    );
};
