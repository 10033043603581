import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SafeSecure } from "components/misc/Headings.js";
import videoimage from "../../images/videoimage.png";
import { ReactComponent as SvgDecoratorBlob3 } from "images/bg-svgs/a.svg";

const Container = tw.div`relative bg-gradient-to-t -mt-24`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-11/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full mt-32`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SafeSecure)`text-left font-semibold text-primary-100 text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`

// const Statistics = tw.div`mt-2 lg:mt-8 xl:mt-16 flex flex-wrap`
// const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`
// const Value = tw.div`font-bold text-primary-500`
// const Key = tw.div`font-medium text-gray-700`
const DecoratorBlob3 = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute left-0 -my-40 bottom-0 h-48 w-48 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;

export default ({
  textOnLeft = true

}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  // const statistics = [
  //   {
  //     key: "Secure",
  //     value: "So, you don’t need to remember passwords for services you rarely use.",
  //   },
  //   {
  //     key: "Hotels",
  //     value: "479",
  //   },
  //   {
  //     key: "Rooms",
  //     value: "2093",
  //   },
  //   {
  //     key: "Workers",
  //     value: "10347",
  //   }
  // ]

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={videoimage} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>Safe, Secure, Convenient</Heading>
            <Description>Your Digital Identity helps you prove who you
              are when you want to access services online.</Description>
            <br /> <br />
            <div>
              <b> It’s secure:</b> So, you don’t need to remember passwords
              for services you rarely use.
            </div>
            <br />
            <div color="/#endregion">
              <b> It’s convenient:</b>  Use can use it on the go, at any time
              of day.
            </div>
            <br />
            <div>
              <b> It’s private:</b>  Your information is private and protected
              and you can be confident your information is private
              and protected.
            </div>
            <br />
            <div>
              <b> Save time:</b>  It can save your time. You don’t have to wait
              to access the services you need. Set it up once.
              Reuse it again and again whenever you
              need to prove who you are.
            </div>
            {/* <Statistics>
              {statistics.map((statistic, index) => (
              <Statistic key={index}>
                <Value>{statistic.value}</Value>
                <Key>{statistic.key}</Key>
              </Statistic>
              ))}
            </Statistics> */}
          </TextContent>
        </TextColumn>
        <DecoratorBlob3 />
      </TwoColumn>
    </Container>
  );
};
