import React from "react";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; 
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import SixFeatures from "components/features/DashedBorderSixFeatures.js";
import Features1 from "components/features/TwoColSingleFeatureWithStats.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";

const HighlightedText = tw.span`text-primary-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Features1
        heading={
          <>
            Amazing <HighlightedText>Features</HighlightedText>
          </>
        }
      />
      <Features
        heading={
          <>
            Amazing <HighlightedText>Features</HighlightedText>
          </>
        }
      />

      <Pricing
        heading={
          <>
            Flexible <HighlightedText>Plans</HighlightedText>
          </>
        }
      />
      {/* <Testimonial 
        heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
      /> */}

      {/* <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      /> */}
      <SixFeatures
        heading={
          <>
            Amazing <HighlightedText>Features</HighlightedText>
          </>
        }
      />
      <FAQ />
      {/* <Blog
        subheading="Blog"
        heading={<>We love <HighlightedText>Writing</HighlightedText></>}
      /> */}
      <GetStarted />
      <TwoColContactUsWithIllustrationFullForm />

      <Footer />
    </AnimationRevealPage>
  );
};
