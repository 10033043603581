import React from "react";
// import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

// import Hero from "components/hero/BusinessHero";
// import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
// import ThreeColWithSideImage from "components/features/ThreeColWithSideImage";
// import ThreeColSimpleWithImageAndDashedBorder from "components/blogs/ThreeColSimpleWithImageAndDashedBorder";
// import TwoColWithTwoFeaturesAndButtons from "components/features/TwoColWithTwoFeaturesAndButtons.js";
// import WithDigital from "components/features/WithDigital";
// import ThankYouPage from "ThankYouPage";
// import DigitalInBusiness from "components/features/DigitalInBusiness";
import DigitalHero from "components/hero/DigitalHero";
import Digital2ndSection from "components/features/Digital2ndSection";
import Digital3rdSection from "components/features/Digital3rdSection";
import WithDigital4rthSection from "components/features/WithDigital4rthSection";
import MobileSection from "components/features/MobileSection";
import Digital5thSection from "components/features/Digital5thSection";
import GetStarted6th from "components/features/GetStarted6th";

// const HighlightedText = tw.span`text-primary-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <DigitalHero />
      <Digital2ndSection />

      <MobileSection />
      <Digital3rdSection />

      <WithDigital4rthSection />
      <Digital5thSection />
      <GetStarted6th />
      <Footer />
    </AnimationRevealPage>
  );
};
