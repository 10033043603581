import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
// import { ReactComponent  } from "images/bg-svgs/c.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";
// import { PrimaryButton, PrimaryLink as PrimaryButtonBase } from "components/misc/Buttons.js";

const PrimaryBackgroundContainer = tw.div`w-full py-16 lg:py-20 bg-gray-200 rounded-lg relative`
const Row = tw.div`px-4 sm:px-16 justify-center items-center z-10 lg:flex-row text-center lg:text-center`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg mx-auto`
const TextContainer = tw(ColumnContainer)`text-2xl sm:text-4xl font-bold`;
const Subheading = tw.h6`text-primary-100 mt-8`;
const Heading = tw.h5`text-primary-100 font-normal text-xl mt-6`;

const LinksContainer = tw(ColumnContainer)`flex justify-center mt-6 lg:mt-8 flex-col sm:flex-row`;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`shadow hover:bg-gray-lightest text-gray-lightest hocus:text-gray-300 bg-primary-100 hocus:bg-primary-700`;

const SecondaryLink = tw(Link)`text-primary-100 border-primary-100`;

// const DecoratorBlobContainer = tw.div`absolute overflow-hidden`
// const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-0 left-0 w-40 h-40 transform -translate-x-20 translate-y-32 `
// const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 `
export default ({
  subheading = "Ready to get started?",
  heading = "Your Veryfid Identity makes accessing government services online simpler, safer and more secure.",
  primaryLinkText = "Let's Talk",
  primaryLinkUrl = "http://timerse.com",
  secondaryLinkText = "Try for free",
  secondaryLinkUrl = "http://google.com",
  pushDownFooter = false
}) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>

              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
            </TextContainer>
            <LinksContainer>
              {/* <DecoratorBlobContainer>

                <DecoratorBlob1 />
              </DecoratorBlobContainer> */}
              <PrimaryLink href={primaryLinkUrl}>{primaryLinkText}</PrimaryLink>
              <SecondaryLink href={secondaryLinkUrl}>{secondaryLinkText}</SecondaryLink>
            </LinksContainer>
          </Row>


        </PrimaryBackgroundContainer>

      </ContentWithPaddingXl>
    </Container>
  );
};
